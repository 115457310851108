import { Route, Router, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import {
  Header, Intro, Footer, License,
  Introduction,
  Service01, Service02,
  Portfolio,
  Pause
} from './routes'

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Pause />} />
      </Routes>

      {/* <Header />
      <Routes>
        <Route path="/" element={<Intro />} />
        <Route path="/license" element={<License />} />

        <Route path="/company">
          <Route path="introduction" element={ <Introduction /> } />
        </Route>

        <Route path="/service">
          <Route path="01" element={ <Service01 /> } />
          <Route path="02" element={ <Service02 /> } />
        </Route>

        <Route path="/portfolio">
          <Route path="portfolio" element={ <Portfolio /> } />
        </Route>
      </Routes>

      <Footer /> */}
    </div>
  );
}



export default App;