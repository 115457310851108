import { useEffect, useState } from 'react';
import './../App.css';

const center = {
    margin: "0 auto",
    marginTop: "30vh",
    maxWidth: "600px",
    width: "100%"
}

export default function Pause(){
    return(
        <div
        style={center}>
            日頃より株式会社Pavilionサイトをご利用いただき、誠にありがとうございます。<br/>
            サイトメンテナンスのため、 こちらのページはご利用いただけません。<br/>
            ご不便、ご迷惑をおかけいたしますが、 何とぞご理解いただきますようお願い申し上げます。<br/><br/>

            その他、お問い合わせは、下記までご連絡ください。<br/><br/>

            株式会社Pavilion<br/>
            146-0091<br/>
            東京都大田区鵜の木2-2-2 B1F<br/>
            TEL 03-6451-9568<br/>
            FAX 03-6451-9569
        </div>
    )
}